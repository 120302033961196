.staus__bar__container {
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 49;
	width: 100%;
	top: 3%;
	// height: 100%;
	.coins_container {
		position: relative;
		.coins__display__img {
			max-width: 25rem;
			width: 100%;
			position: relative;
		}
		.coins {
			width: 20%;
			position: absolute;
			right: 30%;
			top: 20%;
			color: white;
			font-size: 500%;
		}
	}
	.progress__bar__container {
		position: relative;
		margin: 0 3rem;
		.road {
			max-width: 50rem;
			width: 100%;
			position: relative;
		}
		.car {
			position: absolute;
			width: 15%;
			top: 30%;
			right: -3rem;
			transition: 0.5s ease 0.3s;
			//   left: 0;
		}
	}
	.timer__container {
		position: relative;
		.timer {
			max-width: 25rem;
			width: 100%;
			position: relative;
		}
		.time {
			position: absolute;
			font-size: 500%;
			color: #ffff;
			top: 22%;
			left: 12%;
		}
	}
}

@media screen and (max-device-width: 1400px) {
	.staus__bar__container {
		.coins_container {
			.coins__display__img {
				max-width: 22rem;
			}
			.coins {
				width: 20%;
				right: 22%;
				top: 26%;
				font-size: 480%;
			}
		}
		.progress__bar__container {
			.road {
				max-width: 50rem;
			}
			.car {
				width: 15%;
				top: 15%;
				// left: 0;
			}
		}
		.timer__container {
			.timer {
				max-width: 22rem;
			}
			.time {
				font-size: 480%;

				top: 21%;
				left: 8%;
			}
		}
	}
}

@media screen and (max-device-width: 1200px) {
	.staus__bar__container {
		.coins_container {
			.coins__display__img {
				max-width: 20rem;
			}
			.coins {
				// width: 20%;
				right: 25%;
				top: 24%;
				font-size: 420%;
			}
		}
		.progress__bar__container {
			.road {
				max-width: 45rem;
			}
			.car {
				width: 15%;
				top: 20%;
				// left: 0;
			}
		}
		.timer__container {
			.timer {
				max-width: 20rem;
			}
			.time {
				font-size: 420%;

				top: 25%;
				left: 10%;
			}
		}
	}
}

@media screen and (max-device-width: 992px) {
	.staus__bar__container {
		.coins_container {
			.coins__display__img {
				max-width: 16rem;
			}
			.coins {
				// width: 20%;
				right: 25%;
				top: 24%;
				font-size: 380%;
			}
		}
		.progress__bar__container {
			.road {
				max-width: 39rem;
			}
			.car {
				width: 15%;
				top: 20%;
				// left: 0;
			}
		}
		.timer__container {
			.timer {
				max-width: 16rem;
			}
			.time {
				font-size: 380%;

				top: 25%;
				left: 20%;
			}
		}
	}
}

@media screen and (max-device-width: 768px) {
	.staus__bar__container {
		.coins_container {
			.coins__display__img {
				max-width: 12rem;
			}
			.coins {
				// width: 20%;
				right: 30%;
				top: 21%;
				font-size: 300%;
			}
		}
		.progress__bar__container {
			.road {
				max-width: 32rem;
			}
			.car {
				width: 15%;
				top: 20%;
				// left: 0;
				z-index: 1;
			}
		}
		.timer__container {
			.timer {
				max-width: 12rem;
			}
			.time {
				font-size: 300%;

				top: 20%;
				left: 12%;
			}
		}
	}
}

@media screen and (max-device-width: 576px) {
	.staus__bar__container {
		.coins_container {
			.coins__display__img {
				max-width: 12rem;
			}
			.coins {
				// width: 20%;
				right: 30%;
				top: 24%;
				font-size: 22px;
			}
		}
		.progress__bar__container {
			.road {
				max-width: 30rem;
			}
			.car {
				width: 15%;
				top: 20%;
				// left: 0;
				z-index: 1;
			}
		}
		.timer__container {
			.timer {
				max-width: 12rem;
			}
			.time {
				font-size: 22px;

				top: 25%;
				left: 10%;
			}
		}
	}
}

@media screen and (max-device-height: 576px) {
	.staus__bar__container {
		.coins_container {
			.coins {
				font-size: 22px;
			}
		}

		.timer__container {
			.time {
				font-size: 22px;
			}
		}
	}
}
