$primary: rgba(165, 61, 111, 1);
$primary_fade: rgba(165, 61, 111, 0.8);
$gradient_bg: linear-gradient(267.53deg, #e94944 0%, #ef7068 100%),
	linear-gradient(266.53deg, #ffcecb 0%, #ffa19e 100%);
$secondary: #ef7068;

:export {
	primary: $primary;
	primaryFade: $primary_fade;
	gradientBG: $gradient_bg;
	secondary: $secondary;
}
