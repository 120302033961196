@import "../../utils/_colors.scss";

.registration__container {
	max-width: 70rem;
	display: flex;
	margin: auto;
	justify-content: center;
	align-items: center;
	height: 100vh;

	.form__control:not(:last-child) {
		margin-bottom: 2rem;
	}
	.landing_container {
		border: 2px solid $primary;
		text-align: center;
		width: fit-content;
		border-radius: 15px;

		.landing_content_image {
			width: 160px;
			height: 140px;
			padding: 1rem;
			object-fit: contain;
		}
	}

	.form__control {
		position: relative;
		margin-top: 20px;

		select,
		input {
			width: 80%;
			font-size: 2rem !important;
			outline: none;
			border: 1px solid $primary;
			border-radius: 8px;
			width: 100%;
			padding: 10px;
			margin-bottom: 1rem;

			background-color: rgb(245, 245, 245);
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;

			&::-ms-expand {
				display: none;
			}
		}
		img {
			width: 20rem;
			width: 22rem;
			position: relative;
			// left: 3.2rem;
			left: -1.8rem;
			margin: auto;
			cursor: pointer;
		}
		.button__text {
			position: absolute;
			font-size: 3rem;
			color: #ffff;
			top: 19%;
			left: 32%;
			cursor: pointer;
		}
		select {
			background: url("../../assets/images/i.png") #ffff 95%;
			background-repeat: no-repeat;
		}
	}
	.error__msg {
		color: white;
		font-size: 2rem;
		text-align: center;
	}
}

._button_container {
	display: flex;
	justify-content: center;
	._button {
		background-color: $primary;
		color: white;
		font-size: 16px;
		margin-top: 20px;
		&:hover {
			background-color: $primary_fade;
		}
	}
}

.MuiAlert-standardError {
	background-color: #fce4e4 !important;
	border: 2px solid #fcc2c3 !important;
	color: #d8000c !important;
}
.MuiAlert-message {
	font-size: 12px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

@media screen and (max-device-height: 512px) {
	.registration__container {
		.form__control:not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}
}
