.lay-out__container {
	// background: url('../../../assets/images/backgrounds/bg_slide_0.png');
	// background-image: url("../../../assets/short-game-assets/w-pattern-bg.svg");
	// height: 100vh;
	// width: 1000vw;

	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	min-width: 100vw;
}
