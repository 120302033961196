.app_alert {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #a53c6f;
}
.app_msgBox {
  position: relative;
  height: 150px;
  text-align: center;
  width: 38%;
  background-color: #bb4d83;
  border-radius: 20px;
  padding-top: 10px;
}
.app_congrats_text{
    margin-top: 25px;
    font: cubano;
    font-size: 40px !important;
    color: #ffff;
    font-weight: 900 !important;
}
.app-text2{
  font: cubano;
  font-weight: 800 !important;
  font-size: 20px;
  color: #ff7aba;
  margin-top: 10px;
}
// @media screen and (max-device-width: 780px) {
//   .paintings__wall__container {
//     .painting__container {
//       max-width: 85%;
//       .frame {
//         max-height: 50%;
//         max-width: 20%;
//         border: 1.5rem solid black;
//         padding: 1rem;
//       }
//     }
//   }
// }
@media screen and (max-device-width: 375px){
  .app_msgBox{
    width: 360px;
    .app_congrats_text{
      font-size: 28px !important;
      font-weight: 900;
    }
    .app-text2{
      font-size: 16px !important;
      font-weight: 900;
    }
  }
}
@media screen and (max-device-width: 414px){
  .app_msgBox{
    width: 370px;
    .app_congrats_text{
      font-size: 28px !important;
      font-weight: 900;
    }
    .app-text2{
      font-size: 16px !important;
      font-weight: 900;
    }
  }
}
@media screen and (max-device-width: 820px){
  .app_msgBox{
    width: 650px;
    .app_congrats_text{
      font-size: 40px !important;
      font-weight: 900;
    }
    .app-text2{
      font-size: 24px !important;
      font-weight: 900;
    }
  }
}
@media screen and (max-device-width: 1180px){
  .app_msgBox{
    width: 650px;
    .app_congrats_text{
      font-size: 40px !important;
      font-weight: 900;
    }
    .app-text2{
      font-size: 24px !important;
      font-weight: 900;
    }
  }
}
@media screen and (max-device-width: 1368px){
  .app_msgBox{
    width: 650px;
    .app_congrats_text{
      font-size: 40px !important;
      font-weight: 900;
    }
    .app-text2{
      font-size: 24px !important;
      font-weight: 900;
    }
  }
}
@media screen and (max-device-width: 540px){
  .app_msgBox{
    width: 500px;
    .app_congrats_text{
      font-size: 40px !important;
      font-weight: 900;
    }
    .app-text2{
      font-size: 24px !important;
      font-weight: 900;
    }
  }
}
@media screen and (max-device-width: 280px){
  .app_msgBox{
    width: 300px;
    .app_congrats_text{
      font-size: 40px !important;
      font-weight: 900;
    }
    .app-text2{
      font-size: 20px !important;
      font-weight: 900;
    }
  }
}
@media screen and (max-device-width: 653px){
  .app_msgBox{
    width: 300px;
    height: 200px;
    .app_congrats_text{
      font-size: 40px !important;
      font-weight: 900;
    }
    .app-text2{
      font-size: 20px !important;
      font-weight: 900;
    }
  }
}
@media screen and (max-device-width: 412px){
  .app_msgBox{
    width: 300px;
    height: 200px;
    .app_congrats_text{
      font-size: 40px !important;
      font-weight: 900;
    }
    .app-text2{
      font-size: 20px !important;
      font-weight: 900;
    }
  }
}
