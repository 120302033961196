.asher__tap__to__continue {
  position: absolute;
  bottom: 24vh;
  right: 5%;
  color: #ffff;
  font-weight: bold;
  font-size: 2rem;
  cursor: pointer;
  background-color: #2090FF;
  padding: 5px 15px;
  z-index: 99;
  border-radius: 8px;
  font-size: 16px;
}

.asher__intro__logo__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .play__logo {
    width: 20%;
  }
}
