* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: Lato, sans-serif;
}
@font-face {
	font-family: Lato, sans-serif;
	src: local("Lato, sans-serif"),
		url(./assets/fonts/Lato-Regular.ttf) format("truetype");
}

html {
	font-size: 62.5%;
}

body {
	touch-action: manipulation;
	margin: 0px !important;
}
