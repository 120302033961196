@import "../../../utils/colors";

._audio_controls {
	width: 100%;
	position: fixed;
	top: 3.5%;
	margin-right: auto;
	margin-left: auto;
	left: 0;
	right: 0;
	text-align: center;

	z-index: 50;
	display: inline-block;

	.MuiButton-text {
		padding: 10px;
	}

	.MuiButton-root {
		min-width: unset;
	}

	._audio_button {
		.MuiButton-label {
			width: 24px;
			height: 24px;
		}

		background: $gradient_bg;
		border-radius: 50%;
	}
}
