// body {
//     // margin: 0;
//     // font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
//     // font-size: 1rem;
//     // font-weight: 400;
//     // line-height: 1.5;
//     // color: #212529;
//     // text-align: left;
//     // background-color: #fff;
//   }
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  
  button,
  input {
    overflow: visible;
  }
  // img {
  //   vertical-align: middle;
  //   border-style: none;
  // }
  .main {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow-y: auto;
    overflow: hidden;
    //font-size: 14px;
    z-index: 3;
    position: absolute;
    right: 0;
    left: 0;
    &.desktop {
      font-size: 16px;
      .inside {
        background: #ffffff0d;
        display: flex;
        align-items: center;
        border: 1px solid #313131;
        width: 650px;
        padding: 1em;
        overflow-y: hidden;
      }
    }
    .inside {
      width: 95%;
      padding: 0 1em;
      border-radius: 1em;
    }
  }
  
  // .cu-btn {
  //   // background-image: linear-gradient(to right, #26d8a4 0%, #1eb78a 52%, #0c866e 100%);
  //   background-color: #954f74;
  //   color: #fff;
  //   border: none;
  //   border-radius: 0.2em;
  //   width: 100%;
  //   padding: 0.5em;
  //   font-size: 1.2em;
  //   cursor: pointer;
  //   padding: 0.5em;
  //   height: 54px;
  //   font-size: 1.4em;
  // }
  
  /* Chrome, Safari, Edge, Opera
  For removing stepper from input type number */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .rotate_screen{
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1000;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    width: auto;
    div{
      width: 90%;
      margin: 0 auto;
      text-align: center;
      font-size: 24px;
    }
  }
  .overlay_loader {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .txt {
      position: absolute;
      left: 0;
      right: 0;
      align-items: center;
      text-align: center;
      width: 65px;
      height: 65px;
      background: #08eeaad4;
      margin: 0 auto;
      border-radius: 5em;
      display: flex;
      justify-content: center;
      div {
        font-size: 0.95em;
      }
    }
  }
  .w-h-100{
    width: 100%;
    height: 100%;
  }
  .clr-white{
    color: white;
  }
  
@import "src/components/asherintro/style.scss";
  
  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    select:focus,
    textarea:focus,
    input:focus {
      font-size: inherit !important;
    }
  }
  
  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    select,
    textarea,
    input {
      font-size: inherit !important;
    }
  }
  