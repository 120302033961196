.alert__container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiAlert-root {
    font-size: 4rem;
    cursor: pointer;
  }
}
